.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.auth-container{
  height: 100vh;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.App {
  /* text-align: center; */
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.profileImage {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: #512DA8;
  font-size: 35px;
  color: #fff;
  text-align: center;
  line-height: 150px;
  margin: 20px 0;
}


.text-line-break{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.loading-overlay {
 
}

.client-name {
  color: #333; /* Default color */
  cursor: pointer; /* To indicate it's clickable */
}

.client-name:hover {
  color: #007bff; /* Color change on hover (blue in this case) */
}

.ant-modal .ant-modal-close {

  right: 25px !important;
}
.ant-upload-list-item-error {
  color: #ff9800 !important; /* Örneğin turuncu bir renk */
}

/* Liste metninin genel rengini değiştirmek */
.ant-upload-list-item {
  color: #007bff; /* Örneğin yeşil bir renk */
}

/* İcon renklerini değiştirmek */
.ant-upload-list-item-error .anticon-close {
  color: #007bff !important; /* İkon rengi */
}
/* Ana telefon ekranı stilini koruyalım */
.smartphone {
  width: 375px; /* Telefon ekranı genişliği */
  height: 667px; /* Telefon ekranı yüksekliği */
  border-radius: 20px; /* Köşeleri yuvarlatıyoruz */
  margin: auto; /* Ortaya hizalıyoruz */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Gölgelendirme */
  overflow: hidden; /* Dışarı taşan herhangi bir şeyi gizliyoruz */
}

/* İçerik divi için kaydırma çubuğu */
.smartphone .content {
  max-height: 450px; /* İçeriğin yüksekliğini sınırlıyoruz */
  overflow-y: auto; /* Yalnızca dikey kaydırma aktif */
  padding: 10px; /* İçerik etrafına boşluk ekliyoruz */
  box-sizing: border-box; /* Padding ve border'ı içeriğin boyutlarına dahil ediyoruz */
}

/* Scrollbar'ın görünümünü özelleştiriyoruz */
.smartphone .content::-webkit-scrollbar {
  width: 6px; /* Kaydırma çubuğunun genişliği */
}

/* Scrollbar arka plan rengi */
.smartphone .content::-webkit-scrollbar-track {
  background: #f1f1f1; /* Kaydırma alanının arka planı */
  border-radius: 10px;
}

/* Scrollbar thumb (kaydırıcı) */
.smartphone .content::-webkit-scrollbar-thumb {
  background: #888; /* Kaydırıcı rengi */
  border-radius: 10px;
}

/* Hover olduğunda scrollbar thumb rengi değişir */
.smartphone .content::-webkit-scrollbar-thumb:hover {
  background: #555; /* Hover rengi */
}
@media (max-width: 768px) {
  .smartphone {
      width: 100%;
      height: auto;
  }
}
.hover-button {
  background-color: white; /* Daha açık parlak gri */
  color: #25D366;
  padding: 8px 12px;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease; /* Hover animasyonu */
}

.hover-button:hover {
  background-color: #25D366; /* Hoverda biraz daha açık gri */
  color: white; /* Hoverda biraz daha açık gri */
  transform: scale(1.05); /* Hoverda butonu biraz büyüt */
}


.ml-8{
  margin-left: 2rem;
}

.ml-32{
  margin-left: 8rem;
}
.primary-bg{
  background-color: #1DA1F2;
}
.wa-color{
  color: rgb(19, 143, 91)
}

.row-justify-between{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.row-justify-end{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}

.row-align-center{
  display: flex;
  flex-direction: row; 
  align-items: center;
}
.row-align-start{
  display: flex;
  flex-direction: row; 
  align-items: flex-start;
}
.max-w-150{
  max-width: 150px;
}
.max-w-250{
  max-width: 250px;
}
#textarea-emoji{
  position: absolute !important; /* Araç çubuğunu sabitle */
  top: -50px !important;        /* Yukarı taşı */
  left: 0 !important;           /* Sol hizala */
  z-index: 10;                  /* Üstte görünmesi için z-index ekle */
}

#tab-toolbar {
  z-index: 10; /* Emoji seçim menüsünün üstte görünmesini sağlar */
}

.ql-container{
  height: calc(100% - 42px) !important;
}

.ql-editor {
  height: 100% !important;
}
.ant-radio{
  top:0 !important;
}