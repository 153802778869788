.smartphone {
    /* position: relative;
    width: 300px;
    height: 524px;
    margin: auto;
    border: 10px black solid;
    border-top-width: 10px ;
    border-bottom-width: 10px;
    border-radius: 50px; */
    position: relative;
    width: 290px;
    height: 524px;
    margin: auto;
    border: 5px black solid;
    border-top-width: 5px ;
    border-bottom-width: 5px;
    border-radius: 50px;
  }
  /* .smartphone {
    position: relative;
    width: 360px;
    height: 640px;
    margin: auto;
    border: 16px black solid;
    border-top-width: 60px;
    border-bottom-width: 60px;
    border-radius: 36px;
  }
   */
  /* The horizontal line on the top of the device */
  .smartphone:before {
    content: '';
    display: block;
    width: 70px;
    height: 5px;
    position: absolute;
    bottom: -3px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: grey;
    border-radius: 15px;
  }
  
  /* The circle on the bottom of the device */
  .smartphone:after {
    content: '';
    display: block;
    width: 100px;
    height: 15px;
    position: absolute;
    left: 50%;
    /* top: -0px; */
    top:4px;
    transform: translate(-50%, -50%);
    background: black;
    border-radius: 15px;
  }
  body {
    background: #f4f6fc;
   
  }
  
  .iphone-x {
    position: relative;
    margin: 40px auto;
    width: 300px;
    height: 540px;
    background-color: #7371ee;
    background-image: linear-gradient(60deg, #7371ee 1%,#a1d9d6 100%);
    border-radius: 40px;
    box-shadow: 0px 0px 0px 11px #1f1f1f, 0px 0px 0px 13px #191919, 0px 0px 0px 20px #111;
    
    &:before,
    &:after{
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    
    
    &:after {
      bottom: 7px;
      width: 140px;
      height: 4px;
      background-color: #f2f2f2;
      border-radius: 10px;
    }
    
    
    &:before {
      top: 0px;
      width: 56%;
      height: 30px;
      background-color: #1f1f1f;
      border-radius: 0px 0px 40px 40px;
    }
    
    i,
    b,
    s,
    span {
      position: absolute;
      display: block;
      color: transparent;
    }
    
    
    i {
      top: 0px;
      left: 50%;
      transform: translate(-50%, 6px);
      height: 8px;
      width: 15%;
      background-color: #101010;
      border-radius: 8px;
      box-shadow: inset 0px -3px 3px 0px rgba(256, 256, 256, 0.2);
    }
    
    
    b {
      left: 10%;
      top: 0px;
      transform: translate(180px, 4px);
      width: 12px;
      height: 12px;
      background-color: #101010;
      border-radius: 12px;
      box-shadow: inset 0px -3px 2px 0px rgba(256, 256, 256, 0.2);
      
      &:after {
        content: '';
        position: absolute;
        background-color: #2d4d76;
        width: 6px;
        height: 6px;
        top: 2px;
        left: 2px;
        top: 3px;
        left: 3px;
        display: block;
        border-radius: 4px;
        box-shadow: inset 0px -2px 2px rgba(0, 0, 0, 0.5);
      }
    }
    
    
    s {
      top: 50px;
      color: #fff;
      text-align: center;
      text-decoration: none;
      width: 100%;
      font-size: 70px;
      font-weight: 100;
      padding-top: 60px;
    }
    
    
    span {
      bottom: 50px;
      width: 40px;
      height: 40px;
      background-color: rgba(0, 0, 0, 0.3);
      border-radius: 50%;
      left: 30px;
      
      & + span {
        left: auto;
        right: 30px;
      }
    }
  }